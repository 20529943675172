.dashboard-creator-container {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.5rem;
  height: 95vh;
  margin: 0 auto;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  overflow: auto;
}

.projects-page {
  overflow-y: auto;
  height: 95vh;
}
.projects-page::-webkit-scrollbar {
  width: 10px;
}

.projects-page::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.tableDiv2 {
  width: 100%;
  padding: 2rem;
}
.digital-twin-container {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.5rem;
  height: 95vh;
  margin: 0 auto;
  border-radius: 8px;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* overflow: auto; */
}
.project-list-container {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.5rem;
  margin: 0 auto;
}

.drm-cards-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
}

header {
  margin-bottom: 20px;
}

h1 {
  margin: 0;
  font-size: 28px;
  color: #333;
}

p {
  margin: 10px 0 0;
  font-size: 14px;
  color: gray;
}

.dashboard-content {
  display: flex;
  flex-direction: row;
  height: 75vh;
  gap: 1rem;
  /* border-bottom: #333 solid 1px; */

  /* align-items: center; */
}
.dt-voice-content {
  display: flex;
  flex-direction: column;
  height: 75vh;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  border-radius: 12px;
}
.dashboard-projects {
  display: flex;
  gap: 1rem;
}
.dashboard-left {
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-items: start; */
  /* border-bottom: 1px solid #ccc;*/
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  height: 100%;
  flex: 1;
}
.avtar-img {
}
.dashboard-right {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
}
.dashboard-Consent {
  border-bottom: 1px solid rgba(179, 179, 179, 0.544);

  padding: 1rem;
  height: 50%;
}

.dashboard-recent-projects {
  padding: 1rem;
  height: 50%;
  position: relative;
  overflow: hidden;
}
.new-projects-container {
  height: 80%;
  overflow-y: auto;
}
.new-projects-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.new-projects-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.new-projects-container::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  /* border-radius: 10px; */
}

.new-projects-container::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}
.recent-list-projects {
  display: flex;
  align-items: center;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  background-color: #ebedf0;
  gap: 2rem;
  padding-left: 0.8rem;
  height: 25%;
  border-bottom: #ffffff 2px solid;
}
.left-content {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  height: 100%;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-content {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  height: 100%;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.settingsVoiceLab {
  background-color: #ebedf0;
  border-radius: 8px;
  padding: 10px;
}
.textToSpeechContainer {
  all: unset;
  width: 100%;
  height: auto;
  border: none;
  resize: none;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.settingsContainer {
  height: auto;
}
.bar-top-left {
  display: flex;
  justify-content: space-between;
}

.btnGroup {
  display: flex;
  justify-content: start;
}

.tab {
  margin: 0 5px;
  color: #979797;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 800;

  cursor: pointer;
  border-bottom: 2px solid rgba(52, 52, 52, 0);

  /* transition: background-color 0.3s, color 0.3s; */
}

.tab.active {
  color: #333;
  border-bottom: 2px solid rgba(52, 52, 52, 0.599);
}

.input-area {
  width: 80%;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
}

.dashboard-right {
  flex: 1; /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: start; */
  border-bottom: 1px solid #ccc;
  border-radius: 12px;
  height: 100%;
}

h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #333;
}

.slider {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox input {
  margin-right: 10px;
}

.generate-section {
  display: flex;
  width: 100%;
  /* align-items: center; */
  justify-content: space-between;
}
.generate-section2 {
  display: flex;
  width: 100%;
  /* align-items: center; */
}

.generate-btn {
  padding: 8px 15px;
  background-color: #000000;
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.selectVoice-btn {
  padding: 8px 15px;
  background-color: #eaeaea;
  color: rgb(54, 54, 54);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.setDefault-btn {
  padding: 8px 15px;
  width: 100%;
  background-color: #000000;
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generate-btn:hover {
  background-color: #161616;
}

.quota {
  margin-left: 20px;
  font-size: 14px;
  color: gray;
}

footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.playback {
  display: flex;
  align-items: center;
}

.playback span {
  margin-right: 20px;
  font-size: 14px;
  color: #333;
}

.playback-controls button {
  margin: 0 5px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #007bff;
}

.playback-controls button:hover {
  color: #0056b3;
}

.scroll-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 850px;
  overflow: hidden;
  margin-top: 1rem;
}

.scroll-wrapper {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%; /* Adjust based on button size */
  gap: 1rem;
}

.scroll-item {
  min-width: 300px;
  height: 200px;
  text-align: center;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-item img {
  width: 150px;
  height: 220px;
}

.scroll-button-left {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  font-size: 2rem;
  border-radius: 2px;
  background: #3939398a;
  border: none;
  cursor: pointer;
  z-index: 1;
  left: 0;
}
.scroll-button-right {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  border-radius: 2px;
  background: #3939398a;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  z-index: 1;
  right: 0;
}

.fixed-size-table th.fixed-column1,
.fixed-size-table td.fixed-column1 {
  width: 200px; /* Adjust the width as needed */
  text-align: center;
}

.custom-select__menu-list {
  max-height: 200px;
  overflow-y: auto;
}

.custom-select__menu-list::-webkit-scrollbar {
  width: 8px;
}

.custom-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.custom-select__menu-list::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.custom-select__menu-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
