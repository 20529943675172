.spectralTranslate {
    height: 95vh;
    /* padding-top: 2%; */
    overflow-y: auto;
}

.spectralTranslate::-webkit-scrollbar {
    width: 10px;

}

.spectralTranslate::-webkit-scrollbar-thumb {

    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;

}

.langInfo-status {
    display: flex;
    gap: 2px;
}

.translate-welcome {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
    font-size: 20px;
}

.translate-cards-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.no-projects-txt{
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: red;
}

.status-label {
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 12px;
}

.translatePage {
    width: 94%;
    margin-left: 3%;
    margin-top: 2%;
    background-color: #ced4da;
    background-position: center;
    padding: 2%;
    border-radius: 12px;
}

.translateFileUploadContainer {

    text-align: center;
    padding:1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

input[type="checkbox"] {
    border-color: grey;
}

.translateLanguageContainer {
    padding:4%;
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 2vh;
    position: 'relative'
}

.languageList {
    height: 25vh;
    overflow-y: auto;
    padding-left: 5px;

}

.languageList::-webkit-scrollbar {
    width: 10px;

}

.languageList::-webkit-scrollbar-thumb {

    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;

}

.submitBtnContainer {
    display: flex;
    justify-content: center;
    /* margin-top: 20px; */
    height: 80px;
}

.submitBtn {
    border: none;
    outline: none;
    height: 50px;
    width: 200px;
    border-radius: 8px;
    font-weight: 500;
    color: white;
    background-color: #222;
    margin-top: 20px;
}

.submitBtnDisabled {
    border: none;
    outline: none;
    height: 50px;
    width: 200px;
    border-radius: 8px;
    font-weight: 500;
    margin-top: 20px;

}

.input-group-border {
    border: 1px solid black;
    border-radius: 4px;

}



@media (min-width: 1080px) {
    .languageList {
        height: 38vh;
    }
}

@media (min-width: 1600px) {
    .languageList {
        height: 50vh;
    }
}

@media (min-width: 2000px) {
    .languageList {
        height: 60vh;
    }
}

/* Translate Page 2 */

.options-Button {
    border: 1px solid black;
    font-size: 25px;
    outline: none;
    background-color: #f7f7f9;
    padding-top: 0%;
}

.tanslateProjectsCard {
    position: relative;
    width: 26%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

}

.translateCardThumbnail {
    width: 100%;
    border-radius: 8px;
    height: 250px;
    cursor: pointer;
}

.card-text {
    font-size: 16px;
}

@media (min-width: 1080px) {
    .translateFileUploadContainer,.translateLanguageContainer,.translatePage2{
        height:78vh;
    }
    .card-text {
        font-size: 12px;
    }

    .translateCardThumbnail {
        height: 200px;
    }
    
}

@media (min-width: 1600px) {
    .translateFileUploadContainer,.translateLanguageContainer,.translatePage2{
        height:82vh;
    }
    .card-text {
        font-size: 16px;
    }

    .translateCardThumbnail {
        height: 250px;
    }
}

@media (min-width: 2000px) {
    .card-text {
        font-size: 16px;
    }

    .translateCardThumbnail {
        height: 250px;
    }
}

.ready {

    font-size: 14px;
    font-weight: 500;
}

.translate-dubbed-original {
    width: 45%;
}

.viewDownloadButton {
    background-color: white;
    color: grey;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid lightgrey;
}

.translatePage2 {
    height: 82vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 12px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
}

.translatePage2::-webkit-scrollbar {
    width: 10px;

}

.translatePage2::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;
}


.original-video-translate {
    height: 50vh;
}

/* Translate page 3 */

.syncPage5 {
    height: 55vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 12px;
}

.custom-error-toast {
    background-color: rgb(255 240 240);
    color: red;
}

.dubbed-videos::-webkit-scrollbar {
    width: 10px;

}

.dubbed-videos::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;

}