.menu-container{
/*     
    border-right: 1px solid black; */
    height: 100vh;
}
.logo{
    width:80%;
    height:8%;
    margin:10%;
    margin-top: 50px;
}
.new-videoBtn{
    margin:10%;
    background-color: #222;
    border: none;
    border-radius: 8px;
    width:80%;
    height:45px;
    color: white;
    font-size: 13px;
    font-weight:500;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.home-btn{
    margin-left:10%;
    background-color: #eeeef0;
    border: none;
    border-radius: 8px;
    width:80%;
    height:45px;
    text-align: left;
    outline: none;
    font-weight: 600;
}
.common-btn{
    margin-left:10%;
    border: none;
    border-radius: 8px;
    width:80%;
    height:50px;
    text-align: left;
    margin-top: 15px;
    font-weight: 600;
    outline: none;
    background-color: white;
}
.btn-styling{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-left: 10px;
}
@media (min-width: 1080px){
.personalize{
    height:70px;
}

}
@media (min-width: 1600px){
    .personalize{
        height:48px;
    }
  
    }
    @media (min-width: 2000px){
        .personalize{
            height:48px;
        }
       
        }
      