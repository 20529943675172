.voiceLibrary{
    height: 95vh;
    /* padding-top: 1%; */
    overflow-y: auto;

}
.voiceLibrary::-webkit-scrollbar {
    width: 10px;

}

.voiceLibrary::-webkit-scrollbar-thumb {

    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;

}
.voices{
    display:flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 40px;
}

.fileUpload{
    background-color: white;
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;
    height:25%;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 2vh;
    /* margin-left: 30px;
    margin-right: 30px; */
    margin-bottom: 30px;
    margin-top: 20px;
    text-align: center;
}
.samplesTable{
    max-height:360px;
    overflow-y: auto;
}

.samplesTable::-webkit-scrollbar {
    width: 10px;  
   
}
.samplesTable::-webkit-scrollbar-thumb {
   
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px; 
   
}

.add-labels{
    background-color: #edf2f5;
    border-radius: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}

.labels-div{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.label-pair{
    padding-left: 8px;
    padding-right: 8px;
    margin: 2px;
    border-radius: 10px;
    display: inline-block;
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    max-height: 40px;
}
/* @media (min-width: 1080px){
.addVoiceCard{
    height:30vh
}
}

@media (min-width: 1600px){
    .addVoiceCard{
        height:20%
    }
    }
    @media (min-width: 2000px){
        .addVoiceCard{
            height:20vh
        }
        } */

        /* Inside voicelib.css */
.description {
    position: relative;
  }
  
  .more-less-button {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 4px;
    border-radius: 4px;
    display: none;
  }
  
  .vocesCard:hover .more-less-button {
    display: block;
  }
  