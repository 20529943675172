.App22 {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.waveForm {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 800px;
}

.dubbedAudioPage {
  /* width:95%;
    margin-left: 2%;
    margin-top: 2%; */
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding-top: 50px;
  border-radius: 12px;
  overflow: hidden;
  height: 75vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.invisible {
  display: none;
}
h2 {
  margin-top: 0;
}
.subex {
  width: 850px;
}
.exFormContainer {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #ebedf0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group {
  margin-bottom: 20px;
  display: flex;
  gap: 2rem;
}

.exLable {
  display: block;
  width: 100px;
}

select {
  width: 300px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* height: 50px; */
}

.result-audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
}

.form-subgroup {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* flex-direction: column; */
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 5px;
}

.form-actions {
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 1rem;
}

.exBtn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #1d1d1d;
  color: white;
  cursor: pointer;
}
.exBtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid black;
  cursor: pointer;
  background-color: #eeeeee;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.385);
  transition-duration: 500ms;
}

.exBtn2:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.399);
}
