
.rnd-container {
   margin-top: 10px;
  display: flex;
  flex-direction: column;
/* margin-left: 10px; */
/* height: 260px;
width:1500px; */
/* width:1498px; */
overflow: hidden;
/* box-sizing: content-box; */
position:relative;
background-color: aliceblue;
}
.my-rnd-component{
  height: 60px;
}
.my-rnd-component:hover{
  outline : 3px solid orange;
 
}

.toolBarContainer{
  width:100%;
  height:65px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  background-color: #ededed;
  /* margin:100px; */
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
 
}

 .t-button{
  border:none;
  background-color: #ededed;
  font-size:x-large;
  margin-right: 6px;
  outline: none;
}
/*.vplay-button:focus-within{
  border:none;
  font-size: x-large;
  height: 60px;
  outline: none;
}
.play-button{
  border:none;
  background-color: #ededed;
  font-size: 30px;
  height: 60px;
  outline: none;
  
}
.vol-button{
  border:none;
  background-color: #ededed;
  font-size: x-large;
  margin-left: 30px;
  height: 60px;
  outline: none;
}

.modify>button{
border: none;
background-color: #ededed;
margin-left: 10px;
height: 60px;
outline: none;
}

.zoomButtons{
  border: none;
background-color: #ededed;
font-size: x-large;
height: 60px;
outline: none;
} */


/* #seconds{
  width:1500px;
 
} */

#markerScale{
  width: 100%;
  box-sizing: content-box;
      height: 15px;
      position: relative;
      margin-left: 0;
       margin-top: 50px; 
      border-bottom: 2px solid gray;
      border-top: none;
      background-color: #f7f7f9;
            
}

.custom-slider {
 width: 1520px;
  height: 200px;
  outline: none;
  border: none;
  margin-top: -35px;
  overflow: hidden;
  
}

.custom-thumb {
  width: 20px;
  height: 200px;
  background: url("/src/icon.png") no-repeat;
  background-size: 20px 200px;

  border: none;
  outline: none;
  margin-top: 20px;

}

.custom-track {
 display: none;
  height:100px;
  background-color: #ededed;
  margin-top: 50px;
  width:1500px;
  margin-left: 11px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
}

.large-button{
  width:1450px;
  height:100px;
  margin-left: 32px;
  border-radius: 8px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 6px;
  font-weight: 500;
  color: gray;
}

.ui-slider .ui-slider-handle {
  
  height:180px;
     background: url("/src/icon.png") no-repeat; 
    
     /* margin-top: -30px; */
    background-size: 24px 180px;
  background-position: center;  
    overflow: hidden;
    border: none;
    outline: none;
  
  }

  .audioTrackBtn{
    height:65px;
    width:2.5%;
    background-color: #c9cbd4;
    font-size: 25px;
    font-weight: 500;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    outline:none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  .videoTrackBtn{
    height:65px;
    width:2.5%;
    background-color: #ededed;;
    font-size: 25px;
    font-weight: 500;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    outline:none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  /* Audio.js css */

  .audioFileContainer{
    height:65px;
    width:100%;
    border:1px solid lightgrey;
    overflow:hidden;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
     }
     .audioFileContainer::-webkit-scrollbar {
      display: none;
     
  }
     @keyframes moveLeftToRight {
      from {
        transform: translateX(0); /* Start off-screen to the left */
      }
      to {
        transform: translateX(100%); /* Move off-screen to the right */
      }
    }
    
 
  /* Video.js css */
  .videoFileContainer{
    width:100%;
    height:65px;
    border:1px solid lightgrey;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    scroll-behavior: smooth;
  }
  .videoFileContainer::-webkit-scrollbar {
    height:8px;
    cursor: pointer;
    scroll-behavior: smooth;
}
.videoFileContainer::-webkit-scrollbar-thumb {
   
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px; 
 /* position: absolute;
 top:80 */
 margin-top: 3px;
}
  .vidContainerMessage{
    text-align: center;
    background-color: #c9cbd4;
    height:100%;
    width:100%;
    font-weight: 500;
    overflow: hidden;
}
.audioContainerMessage{
  text-align: center;
  color:grey;
background-color: #ededed;
height:100%;
    width:100%;
    font-weight: 500;
    overflow: hidden;
}

