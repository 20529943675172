.spectralTranscend {
  height: 95vh;
  /* padding-top: 1%; */
  overflow-y: auto;
}

.spectralTranscend::-webkit-scrollbar {
  width: 10px;
}

.spectralTranscend::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.syncPage {
  width: 94%;
  margin-left: 3%;
  margin-top: 2%;
  background-color: #ced4da;
  background-position: center;
  padding: 2%;
  border-radius: 12px;
  height: 70vh;
}

/* Transcend Home----------------------------------------------------------------------- */

.transcend-cards-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.transcend-welcome {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  font-size: 20px;
}

.no-projects-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: red;
}

.status-label {
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 12px;
}

.optionsButton {
  border: 1px solid black;
  font-size: 25px;
  outline: none;
  background-color: #f7f7f9;
  padding-top: 0%;
}

.tanscendProjectsCard {
  position: relative;
  width: 26%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.transcendCardThumbnail {
  width: 100%;
  border-radius: 8px;
  height: 200px;
  cursor: pointer;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VTCardThumbnail {
  border-radius: 8px;
  height: 150px;
  width: 100%;
  padding-right: 4rem;
  padding-left: 4rem;

  cursor: pointer;
  background-image: cover;
  /* padding: 2rem; */
}

.card-text {
  font-size: 16px;
}

.options-menu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 180px;
}

.options-menu button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.options-menu button:hover {
  background-color: #f3f3f3;
}

@media (min-width: 1080px) {
  .card-text {
    font-size: 12px;
  }

  .transcendCardThumbnail {
    height: 200px;
  }

  .syncPage {
    margin-top: 1%;
  }
}

@media (min-width: 1600px) {
  .card-text {
    font-size: 16px;
  }

  .transcendCardThumbnail {
    height: 250px;
  }

  .syncPage {
    margin-top: 2%;
  }
}

@media (min-width: 2000px) {
  .card-text {
    font-size: 16px;
  }

  .transcendCardThumbnail {
    height: 250px;
  }

  .syncPage {
    margin-top: 2%;
  }
}

.pg1,
.pg3 {
  background-color: #ced4da;
  padding: 2%;
  margin: 2%;
  border-radius: 12px;
  max-width: 100%;
}

.fileUploadContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 82vh;
  overflow-y: auto;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.fileUploadContainer::-webkit-scrollbar {
  width: 10px;
}

.fileUploadContainer::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}
.video-upload-form {
  position: absolute;
  top: 80;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 2%;
  width: 97%;
}
.track-upload-container {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 2%;
  width: 49.3%;
  overflow: hidden;
}

input[type='checkbox'] {
  border-color: grey;
}

.fileLanguageContainer {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 82vh;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 2vh;
  padding: 3%;
}

.sourceVideo {
  width: auto;
  height: 40vh;
  margin-bottom: 10px;
  /* margin-top: 30px; */
}

.youtubeVideo {
  width: auto;
  height: 40vh;
  margin-bottom: 10px;
  margin-top: 30px;
}

.languageList {
  height: 40vh;
  overflow-y: auto;
  padding-left: 5px;
}

.languageList::-webkit-scrollbar {
  width: 10px;
}

.languageList::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.nxtBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nxtBtn {
  border: none;
  outline: none;
  height: 50px;
  width: 200px;
  border-radius: 8px;
  font-weight: 500;
  color: white;
  background-color: #222;
}

.nxtBtn:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  background-color: grey;
}

.input-group-border {
  border: 1px solid black;
  border-radius: 4px;
}

@media (min-width: 1080px) {
  .languageList {
    height: 38vh;
  }

  .sourceVideo {
    width: auto;
    height: 39vh;
    margin-bottom: 10px;
    /* margin-top: 30px; */
  }

  .fileLanguageContainer,
  .fileLanguageContainer {
    height: '78vh';
  }

  .youtubeVideo {
    width: 30vw;
    height: 36vh;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .uploadProgress {
    top: 0;
    right: 0;
  }
}

@media (min-width: 1600px) {
  .languageList {
    height: 40vh;
  }

  .sourceVideo {
    width: auto;
    height: 42vh;
    margin-bottom: 10px;
    /* margin-top: 30px; */
  }

  .fileLanguageContainer,
  .fileLanguageContainer {
    height: '82vh';
  }
  .fileLanguageContainer .uploadProgress {
    top: -10;
    right: -150;
  }
}

@media (min-width: 2000px) {
  .languageList {
    height: 44vh;
  }
  .sourceVideo {
    width: auto;
    height: 38vh;
    margin-bottom: 10px;
    /* margin-top: 30px; */
  }
  .uploadProgress {
    top: -10;
    right: -150;
  }
}

/* Spectral Transcend Speaker -------------------------------------------------------------------------------- */

.transcendPage2 {
  /* width:95%;
    margin-left: 2%;
    margin-top: 2%; */
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding-top: 50px;
  border-radius: 12px;
  overflow: hidden;
  height: 82vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.scrollList {
  overflow-y: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.scrollList::-webkit-scrollbar {
  width: 10px;
  /* Remove scrollbar space */
  /* background: rgba(0, 0, 0, 0.24);  */
}

.scrollList::-webkit-scrollbar-thumb {
  /* width: 10px; */
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

/* images modal component */

.selectSpeakerPic {
  width: 150px;
  height: 150px;
}

.selectSpeakerPic:hover {
  cursor: pointer;
}

.voicesModal::-webkit-scrollbar {
  width: 10px;
}

.voicesModal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

@media (min-width: 1080px) {
  .transcendPage2 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    height: 78vh;
  }

  .scrollList {
    max-height: 50vh;
  }
}

@media (min-width: 1600px) {
  .transcendPage2 {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1%;
    height: 82vh;
  }

  .scrollList {
    max-height: 60vh;
  }
}

@media (min-width: 2000px) {
  .scrollList {
    max-height: 40vh;
  }
}

.custom-div {
  height: 40px;
  width: 300px;
  /* border: 1px solid black; */
  background-color: white;
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
  transition: border-color 0.2s;
  cursor: text;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.custom-div:focus-within {
  outline: 1px solid black;
  border: 1px solid black;
}

.custom-input {
  width: 270px;
  height: 38px;
  border: none;
  outline: none;
  flex: 1;
}

/* Spectral Transcribe -------------------------------------------------------------------------------------------------------------- */
.transcendTranscribe {
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 82vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  padding: 1%;
  /* padding-bottom: 0; */
  max-width: 100%;
}
.transcendTranscribe::-webkit-scrollbar {
  width: 10px;
}

.transcendTranscribe::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.edit-textBtns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding-right: 10px;
}

/* condition to hide deleted status divs */
.subtitles_div_deleted {
  display: none;
}

.speaker_select {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-radius: 6px;
  width: max-content;
  margin-bottom: 2px;
}

.timers {
  display: flex;
  flex-direction: column;
  border: none;
  margin-top: 2px;
  gap: 6px;
  justify-content: space-between;
  border-radius: 6px;
}

.subtitles {
  height: 100px;
}

.transcripts {
  display: flex;
  flex-direction: row;
  border: none;
  justify-content: space-evenly;
  border-radius: 8px;
  height: 15vh;
  align-items: center;
  background-color: #ebedf0;
  margin-bottom: 8px;
}

.highlighted {
  background-color: #d3e3fd;
}

.vid_sync3 {
  /* width: 100%; */
  /* height: 35vh; */
  max-height: 100%;
  max-width: 100%;
}

#subtitle {
  border: none;
  height: 100px;
}

.timer_input {
  text-align: center;
}

.captions {
  /* overflow-y: auto; */
  max-height: 40vh;
  width: 49.5%;
  max-width: 50%;
}

/* .captions::-webkit-scrollbar {
    width: 10px;
}

.captions::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;

} */

#waveform ::part(cursor) {
  width: 3px;
  background-color: rgb(0 117 255);
}

#waveform ::part(region-handle-right) {
  border-right-width: 3px !important;
  border-right-color: gray !important;
  margin-left: 2%;
  margin-top: 1vh;
  height: 3vh;
}
#waveform ::part(region-handle-left) {
  border-left-width: 3px !important;
  border-left-color: gray !important;
  margin-right: 2%;
  margin-top: 1vh;
  height: 3vh;
}

input[type='radio'] {
  border-color: black;
  margin-right: 15px;
}

/* media queries for transcribe ------------------------------------------------------------------->*/

@media (min-width: 1080px) {
  .transcendTranscribe {
    height: 78vh;
  }
  .transcripts {
    height: 15vh;
    padding-left: 8px;
    padding-right: 8px;
  }
  .vid_sync3 {
    /* width: 100%; */
    /* height: 35vh; */
    /* max-height: 30vh; */
  }
  .video-page {
    /* max-height: 42vh;
    max-width: 25vw; */
  }
  .captions {
    /* overflow-y: auto; */
    max-height: 70vh;
  }
  #waveform {
    /* width:72vw */
    width: 95.5vw;
  }

  .timers {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 1600px) {
  .transcendTranscribe {
    height: 82vh;
  }
  .vid_sync3 {
    /* max-width: 40vw; */
    /* height: 35vh; */
    /* max-height: 42vh; */
  }
  .video-page {
    /* max-height: 50vh;
    max-width: 35vw; */
  }
  .transcripts {
    height: 11vh;
    padding-left: 8px;
    padding-right: 8px;
  }

  .captions {
    /* overflow-y: auto; */
    max-height: 50vh;
  }
  #waveform {
    width: 95.5vw;
  }

  .timers {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 1400px) {
  .captions {
    /* overflow-y: auto; */
    max-height: 70vh !important;
  }
}
@media (min-width: 1919px) {
  .video-page {
    max-height: 64vh;
    max-width: 48vw;
  }
  .captions {
    /* overflow-y: auto; */
    max-height: 64vh;
  }
}

/* Spectral Transcend translate -------------------------------------------------------------------------------------------------------------- */
.pg4 {
  background-color: white;
  padding: 1%;
  border-radius: 12px;
  height: 82vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.translations {
  display: flex;
  flex-direction: row;
  border: none;
  justify-content: space-evenly;
  border-radius: 8px;
  height: 11vh;
  align-items: center;
  background-color: #ebedf0;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
}

.captionTranslate {
  overflow-y: auto;
  max-height: 78vh;
}

.translate-message {
  /* width: auto; */
  text-align: center;
}

/* media queries for page 4 ------------------------------------------------------------------->*/
@media (min-width: 1080px) {
  .translations {
    height: 15vh;
  }
  .pg4 {
    height: '80vh';
  }

  .captionTranslate {
    overflow-y: auto;
    max-height: 64vh;
  }

  .translate-message {
    /* width: 440px; */
    text-align: center;
  }
}

@media (min-width: 1600px) {
  .translations {
    height: 11vh;
    width: 100%;
  }

  .captionTranslate {
    overflow-y: auto;
    max-height: 68vh;
  }
  .pg4 {
    height: '82vh';
  }

  .translate-message {
    /* width: 480px; */
    text-align: center;
  }
}

.captionTranslate::-webkit-scrollbar {
  width: 10px;
}

.captionTranslate::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

/* Spectral Sync page 5 -------------------------------------------------------------------------------------------------------------- */

.result-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}
.custom-error-toast {
  background-color: rgb(255 240 240);
  color: red;
}

/* final video render page */
@media (min-width: 1080px) {
  .finalVideo {
    height: 45vh;
    width: auto;
  }
}

@media (min-width: 1600px) {
  .finalVideo {
    height: 45vh;
    width: auto;
  }
}

/* download final video page */

.result-page {
  width: 75vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Spectral sync video page */

@media (min-width: 1080px) {
  .original-video {
    height: 24vh;
  }

  .spectral-video {
    width: 100%;
  }

  .info-txt {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  .original-video {
    height: 40vh;
  }

  .spectral-video {
    width: 100%;
  }

  .info-txt {
    font-size: 14px;
  }
}

.syncVideo-div {
  height: max-content;
  overflow-y: auto;
}

/* custom video player css */

.custom-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: black;
  color: white;
  border-radius: 8px;
  /* padding-top: 4px;
  padding-bottom: 4px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.custom-controls > div {
  display: flex;
  align-items: center;
}

.custom-controls input[type='range'] {
  width: 100%;
  height: 6px;
}

@media only screen and (max-width: 768px) {
  .custom-controls {
    flex-direction: column;
    align-items: center;
  }

  .custom-controls > div {
    margin-bottom: 10px;
  }

  .custom-controls input[type='range'] {
    width: 80%;
  }
}

.custom-controls-btns {
  color: white;
}

/* is dubbing client confirm */

.confirm-tick {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
