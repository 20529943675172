/*  common css classes */

.spectralPersonalize{
    padding-top: 2%;
    height: 90vh;
    overflow-y: auto;
}

.spectralPersonalizeCommon{
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding-top: 50px;
    border-radius:12px;
   overflow: hidden;
    height: 55vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.spectralPersonalize1,.spectralPersonalize2,.spectralPersonalize3{
    width:94%;
    margin-left: 3%;
    margin-top: 2%;
    background-color: #ced4da;
    /* background-image: url('../../multimedia/spectral_bg.jpg'); */
    background-position: center;
    padding: 2%;
    border-radius: 12px;
}

/* page 1 */
.personalize1{
    display:flex;
    flex-direction: column;
    padding-left: 3%;
    padding-right: 3%;
    height:100%;
    overflow-y: auto;
    overflow-x: hidden;
   
}
.personalize1::-webkit-scrollbar {
    width: 10px;  /* Remove scrollbar space */
    /* background: rgba(0, 0, 0, 0.24);  */
   
}
.personalize1::-webkit-scrollbar-thumb {
    /* width: 10px; */
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px; 
   
}
.personalizeFileUpload{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:35vh;
    align-items: center;
    background-color: white;
    border-radius: 12px;
   position:relative;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Page 2  */
.selected-words{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:55%;
    margin-bottom: 20px;
}
.spectralP2{
    
    overflow: hidden;
    height:100%;
   
   
   
}
.selectableWord span:hover {
    background: #ced4da;
    cursor: pointer;
    text-decoration: none;
  }

  .words{
    cursor:pointer;
    font-size:20px;
  }
.personalize2{
    display:flex;
    flex-direction: column;
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden; 
}
.personalize2::-webkit-scrollbar {
    width: 10px;  /* Remove scrollbar space */
    /* background: rgba(0, 0, 0, 0.24);  */
   
}
.personalize2::-webkit-scrollbar-thumb {
    /* width: 10px; */
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px; 
   
}
@media (min-width: 1080px){
    .selected-words {
        width:62%;
    }
  }
  @media (min-width: 1600px){
    .selected-words {
        width:50%;
    }
  }
  @media (min-width: 2000px){
    .selected-words {
        width:50%;
    }
}
/* page3 */

.personalize3{
    display:flex;
    flex-direction: column;
    padding-left: 3%;
    padding-right: 3%;
    height:100%;
    overflow-y: auto;
    overflow-x: hidden;
}

/* .emptyTable td {
    height:40px;
  } */