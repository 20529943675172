.upload-btn{
    margin-right: 20px;
    font-size: 25px;
    border: none;
    background-color: white;
}
.top-btns{
    text-align: end;
    margin-right: 40px;
    margin-top: 30px;
   
  
}
.export-btn{
    border:none;
    width:100px;
    height:50px;
    border-radius: 8px;
    font-weight: 500;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: rgb(169, 247, 154);
}

.export-btn:hover{
    color: green;
}
.upload-btn:hover{
    
    color:#0097fe;
}
.slider-btns{
    text-align: center;
    margin-top: 30px;
}

.slider-val{
    border:none;
    background-color: #fff1dd;
    text-align: center;
    border-radius: 6px;
    height: 30px;
    font-weight:500;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.rotate-btn{
    border:none;
    background-color: white;
    font-size: 20px;
    margin-left: 160px;
    
}
.rotate-val{
    width:78px;
    background-color: #eeeef0;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    text-align: center;
    height: 30px;
    font-weight: 500;
    
}
.rotate-btn1{
    border:none;
    background-color: white;
    font-size: 20px;
    margin-left: 253px;
    margin-top: 20px;
    
    
}
.position{
    margin-left: auto;
}
.position-val{
    width:78px;
    background-color: #2ae6ff8a;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    text-align: center;
    height: 30px;
    font-weight: 500;
    margin-right: 34px;
}
.position1{
  
   margin-top: 20px;
   
}
