/* App.css */

.voice-lab-container {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.5rem;
  height: 95vh;
  margin: 0 auto;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  overflow: auto;
}

header {
  margin-bottom: 20px;
}

h1 {
  margin: 0;
  font-size: 28px;
  color: #333;
}

p {
  margin: 10px 0 0;
  font-size: 14px;
  color: gray;
}

.content {
  display: flex;
  flex-direction: row;
  height: 62vh;
  gap: 1rem;
  /* border-bottom: #333 solid 1px; */

  /* align-items: center; */
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: start; */
  border-bottom: 1px solid #ccc;
  border-radius: 12px;

  flex: 4;
}
.left-content {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  height: 100%;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-content-VL {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  height: 59vh;
  width: 25vw;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.historyContainer {
  background-color: #ebedf0;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds space between rows */
  width: 100%;
  overflow: auto;
  height: 100%;
}
.historyContainer::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.historyContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.historyContainer::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  /* border-radius: 10px; */
}

.historyContainer::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

.historyRow {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px; 
  
  padding: .5rem 1rem ;

  border-radius: 5px;
}

.serialNumber {
  width: 30px; /* Adjust as needed */
}

.downloadButton {
  /* margin-left: auto; 
  padding: 5px 10px; 
  text-decoration: none;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer; */
}

.downloadButton:hover {
  background-color: #0056b3;
}
.settingsVoiceLab {
  background-color: #ebedf0;
  border-radius: 8px;
  padding: 10px;
}
.textToSpeechContainer {
  all: unset;
  width: 100%;
  height: auto;
  border: none;
  resize: none;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.settingsContainer {
  height: auto;
}
.bar-top-left {
  display: flex;
  justify-content: space-between;
}

.btnGroup {
  display: flex;
  justify-content: start;
}

.tab {
  margin: 0 5px;
  color: #979797;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 800;

  cursor: pointer;
  border-bottom: 2px solid rgba(52, 52, 52, 0);

  /* transition: background-color 0.3s, color 0.3s; */
}

.tab.active {
  color: #333;
  border-bottom: 2px solid rgba(52, 52, 52, 0.599);
}

.input-area {
  width: 80%;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
}

.right {
  flex: 2; /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: start; */
  border-bottom: 1px solid #ccc;
  border-radius: 12px;
  height: 100%;
}

h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #333;
}

.slider {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox input {
  margin-right: 10px;
}

.generate-section {
  display: flex;
  width: 100%;
  /* align-items: center; */
  justify-content: space-between;
}
.generate-section2 {
  display: flex;
  width: 100%;
  /* align-items: center; */
}

.generate-btn {
  padding: 8px 15px;
  background-color: #000000;
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.selectVoice-btn {
  padding: 8px 15px;
  background-color: #eaeaea;
  color: rgb(54, 54, 54);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.setDefault-btn {
  padding: 8px 15px;
  width: 100%;
  background-color: #000000;
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generate-btn:hover {
  background-color: #161616;
}

.quota {
  margin-left: 20px;
  font-size: 14px;
  color: gray;
}

footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.playback {
  display: flex;
  align-items: center;
}

.playback span {
  margin-right: 20px;
  font-size: 14px;
  color: #333;
}

.playback-controls button {
  margin: 0 5px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #007bff;
}

.playback-controls button:hover {
  color: #0056b3;
}
