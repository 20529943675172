@import "datatables.net-dt";

.settings1 {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 100%;
  height: 350px;
}

.segmentPage2 {
  overflow-y: auto;
  height: 90vh;
}
.segmentPage2::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.fileToolBar2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #f1f3f4;
  height: 100px;
  width: fit-content;
  padding-right: 20px;
  padding-left: 20px;
}

.segments-table-div {
  background-color: white;
  height: 82vh;
  border-radius: 12px;
  padding: 1%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tableDiv {
  height: 69vh;
  overflow-y: auto;
}
.tableDiv1 {
  height: fit-content;
  overflow-y: auto;
}

.tableDiv::-webkit-scrollbar {
  width: 10px;
}

.tableDiv::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

.table {
  --bs-table-bg: none !important;
}

@media (min-width: 1080px) {
  .segments-table-div {
    height: 78vh;
  }
  .tableDiv {
    height: 62vh;
  }
}
@media (min-width: 1600px) {
  .segments-table-div {
    height: 82vh;
  }
  .tableDiv {
    height: 69vh;
  }
}
.segmentTextDiv {
  background-color: #ebedf0;
}

.edit-audio::-webkit-scrollbar {
  width: 10px;
}

.edit-audio::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

/* record audio css */
.record-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: none;
  background-color: #f1f3f4;
  padding: 5%;
  justify-content: center;
  border-radius: 12px;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 0;
}
.record-div-play {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #dee2e6;
  padding: 2%;
  border-radius: 12px;
  /* align-items: center; */
  position: relative;
  margin-top: 1%;
  margin-bottom: 0;
  /* height: 36vh; */
}

.play-download-recording {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.record-data {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 49.5%;
  height: auto;
}

.record-data-video {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 49.5%;
  height: auto;
}

.audio-player-timer {
  width: max-content;
  /* padding-left: 20px; */
  padding-right: 20px;
  background-color: #f1f3f4;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#audio-Player::-webkit-media-controls-time-remaining-display,
#audio-Player::-webkit-media-controls-current-time-display {
  display: none;
}

#audio-recording::-webkit-media-controls-time-remaining-display,
#audio-recording::-webkit-media-controls-current-time-display {
  display: none;
}

#audio-recording::-webkit-media-controls-play-button {
  display: none;
}

.hidden-region {
  display: none;
}

.custom-button {
  border: none;
  outline: none;
}

.custom-button:disabled {
  border: none;
  outline: none;
}

/* for highlighting the overlap audio-segments  */
.light-red {
  background-color: lightcoral;
}

.light-yellow {
  background-color: lightyellow;
}

.dt-layout-row ::-webkit-scrollbar {
  width: 10px;
}
.dt-layout-row ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}
.dt-layout-row {
  font-size: 0.9rem !important;
}
.dt-length {
  display: flex;
  gap: 10px;
}
.highlight-red {
  background-color: #ffd6d9 !important; /* Set the row background color to red */
}
.highlight-yellow {
  background-color: lightyellow !important; /* Set the row background color to red */
}
