@import url("./css-files/menu.css");
@import url("./css-files/userpage.css");
@import url("./css-files/assets.css");
@import url("./css-files/player.css");
@import url("./css-files/properties.css");
@import url("./css-files/mediaplayer.css");
@import url("./css-files/toolbar.css");
@import url("./css-files/scale.css");
@import url("./css-files/leftNavBar.css");
@import url("./css-files/rcSlider.css");

.App {
  font-family: "Mulish", sans-serif;
}

.credit-range {
  width: 100%;
}
.mob-logo {
  width: 80%;
  height: 8%;
}

p {
  text-decoration: none;
  color: #333;
}

p:hover {
  color: #333;
}
.add-media {
  width: 98%;
  height: 80px;
  background-color: #eee;
  border: none;
  margin-top: 10px;
  font-weight: 500;
  color: gray;
  margin-left: 1%;
  /* margin-right: 1%; */
}
.custom-seek-bar {
  display: flex;
  justify-content: space-between;
  /* width: 98%; */
  width: 1800px;
  /* margin-top: 50px; */
  font-weight: 500;

  margin-top: 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
}

.marking {
  position: relative;
  height: 10px;
  color: gray;
  font-size: 12px;
  font-weight: bold;
}

.marking.big::after {
  content: attr(data-label);
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.marking.small {
  width: 1px;
  background-color: #ccc;
  margin-top: 5px;
}

.videoSlide::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: 102px; /* Centers thumb on the track */
  background-color: #0097fe;
  height: 6rem;
  width: 5px;
}

#mediaFile4 {
  width: 400px;
  height: 80px;
  background: indianred;
  border-radius: 10px;
  outline: 2px solid black;
}

#mic{
  background-color: #ebedf0;
}
#mic{
  background-color: #ebedf03f;
}