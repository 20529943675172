.dashboard{
    display:grid;
    grid-template-columns: 15% 85%;
    grid-auto-rows: minmax(100%,100%);
    grid-template-areas: 'sidebar content';
    
}
.menu-container{
    grid-area: sidebar;
    background-color: white;
    overflow: scroll;
}
.menu-container::-webkit-scrollbar {
    display: none;
  }
.user-content{
    grid-area: content;
}

.dashboard-locked{
    display:grid;
    grid-template-columns: 100%;
    grid-auto-rows: minmax(100%);
    grid-template-areas: 'content';
}