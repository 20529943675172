.admin-home{
  
    height: 90vh;
    padding: 1%;
    overflow-y: auto;
  
}

.admin-home::-webkit-scrollbar {
    width: 10px;  
   
}

.admin-home::-webkit-scrollbar-thumb {
   
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px; 
   
}

.edit-user-details {

    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding:3%;
    border-radius: 12px;
    overflow-y: auto;
    height: 75vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.edit-user-details::-webkit-scrollbar {
    width: 10px;
}

.edit-user-details::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px;

}

/* Admin all projects page */

.projects-cards-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}