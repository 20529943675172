.regFormContainer{
    height:75%;
    width:400px;
    background-color: black;
    border-radius: 20px;
    color:white;
    display:flex;
    flex-direction: column;
   line-height: 20px;
   
}
.googleButton{
    color:white;
    background-color: black;
    border: 1px solid white;
    height:40px;
    border-radius: 6px;
    margin-top: 20px;
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
}
.forgotPwd{
    color:lightblue;
    background-color: black;
    border: none;
    margin-left: 5%;
    margin-top: 20px;
}
.continueBtn{
    width:90%;
    height:40px;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    outline: none;
}

.continueBtn:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: grey;
  }

.signup{
    color:lightblue;
    background-color: black;
    border: none;
    outline: none;
   
}
.checkboxes{
    display:flex;
    flex-direction: column;
    margin-left: 5%;
    
}
.backtoStudio{
    color:lightblue;
    background-color: black;
    border: none;
   margin-left: 25%;
    margin-top: 5%; 
    margin-bottom: 5%;
    text-align: center;
}
.userForm::-webkit-scrollbar {
    display: none;
  }
  .loginPage::-webkit-scrollbar {
    display: none;
  }
  .signUpPage::-webkit-scrollbar {
    display: none;
  }