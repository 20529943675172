.uploadMedia{
    width:75%;
    height:150px;
    border: none;
    outline: 1px dashed #c9cbd4;
    background-color: white;
    border-radius: 6px;
    margin-top: 20px;
}
.uploadAudio{
  /* width:75%;
  height:150px; */
  border: none;
  outline: 1px dashed #c9cbd4;
  background-color: white;
  border-radius: 6px;
  margin-top: 20px;
}
.uploadMedia:hover,.uploadAudio:hover{
    outline:1px dashed #0097fe;
    background-color: #d1e8f782;
}
.videoInfo,.audioInfo{
    width:150px;
    height:100px;
    background-color: #c9cbd4;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}

.videoInfo:hover{
    /* outline:1px solid #0097fe; */
    cursor: pointer;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}
@media (min-width: 1080px){
  .videoInfo,.audioInfo{
    width:250px;
    height:100px;
    background-color: #c9cbd4;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}
}
@media (min-width: 1600px){
  .videoInfo,.audioInfo{
    width:150px;
    height:100px;
    background-color: #c9cbd4;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}
}
.uploadAudio{
    width:250px;
    height:250px;
    border: none;
    outline: 1px dashed #c9cbd4;
    background-color: white;
    border-radius: 6px;
}
.vidAssets{
    display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap between videos as needed */
  justify-content: center; 
  margin-top: 20px;

}

.dotsButton {
    position: absolute;
    top: 2px;
    right: 3px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
    color: white;
    z-index: 1;
    margin-left: auto;
  }

  .videoOptions {
    position: relative;
    display: flex; /* Display images and buttons in a row */
    align-items: center; /* Align items vertically */
  }

  .videoInfo {
    position: relative;
    display: inline-block;
    margin: 10px;
  }
  
 

  

  

