.rc-slider {
    width: 500px;
    
    margin-top: 80px;
    margin-left: 50px;
    background-color: beige;
  }
  
  .rc-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: 30px;
  }
  
  .rc-slider-track {
    height: 80px;
  }
  
  .rc-slider-rail {
    height: 80px;
  }





  
  
  
  
  
  