.settings {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 100%;
  height: 380px;
}

.editTableContainer {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
}
.audioContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.segmentPage2 {
  overflow-y: auto;
  height: 90vh;
}
.segmentPage2::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.fileToolBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #f1f3f4;
  height: 100px;
  width: 600px;
  padding-right: 15px;
  margin-bottom: 50px;
}

.segments-table-div {
  background-color: white;
  height: 82vh;
  border-radius: 12px;
  padding: 1%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tableDiv {
  height: 69vh;
  overflow-y: auto;
}

.tableDiv::-webkit-scrollbar {
  width: 10px;
}

.tableDiv::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

@media (min-width: 1080px) {
  .segments-table-div {
    height: 78vh;
  }
  .tableDiv {
    height: 62vh;
    overflow-y: auto;
  }
}
@media (min-width: 1600px) {
  .segments-table-div {
    height: 82vh;
  }
  .tableDiv {
    height: 69vh;
  }
}
.segmentTextDiv {
  background-color: #ebedf0;
}

.edit-audio::-webkit-scrollbar {
  width: 10px;
}

.edit-audio::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}

/* record audio css */
.record-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: none;
  background-color: #f1f3f4;
  padding: 5%;
  justify-content: center;
  border-radius: 12px;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 0;
}
.record-div-play {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #dee2e6;
  /* padding: 5%; */
  border-radius: 12px;
  /* align-items: center; */
  position: relative;
  margin-top: 1%;
  margin-bottom: 0;
}

.play-download-recording {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.record-data {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 49.5%;
  height: auto;
}

.record-data-video {
  padding: 20px;
  background-color: #ebedf0;
  border-radius: 8px;
  width: 49.5%;
  height: auto;
}

.audio-player-timer {
  width: max-content;
  /* padding-left: 20px; */
  padding-right: 20px;
  background-color: #f1f3f4;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#audio-Player::-webkit-media-controls-time-remaining-display,
#audio-Player::-webkit-media-controls-current-time-display {
  display: none;
}

#audio-recording::-webkit-media-controls-time-remaining-display,
#audio-recording::-webkit-media-controls-current-time-display {
  display: none;
}

#audio-recording::-webkit-media-controls-play-button {
  display: none;
}

#split_panel ::part(region-handle region-handle-left) {
  display: none;
}
#split_panel ::part(region-handle region-handle-right) {
  width: 5px;
  background-color: grey;
}

.custom-button {
  border: none;
  outline: none;
}

.custom-button:disabled {
  border: none;
  outline: none;
}
