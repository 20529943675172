.profile{
  
    height: 90vh;
    padding: 1%;
    overflow-y: auto;
  
}

.profileScroll::-webkit-scrollbar {
    width: 10px;  
   
}

.profileScroll::-webkit-scrollbar-thumb {
   
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px; 
   
}
