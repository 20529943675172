.navContainer{
  height:100%
}
.leftNav{
    display: flex;
    flex-direction: row;
    font-size: 16px;
   color: black;
   font-weight: 500;
   text-align: center;
   /* border-right: 1px solid rgba(128, 128, 128, 0.247); */
   background-color: white;
   border: 1px solid black;
   height: 100%;
   width: 100%;
}

.leftNav:hover{
    color: #0d6efd ;
}

.icon {
    color: #c9cbd4;
    transition: color 0.3s; /* Adding a smooth transition effect */
    font-size: 35px;
    position: relative;
    overflow: hidden;
    
   
  }
 

  p:hover{
    color: #0d6efd;
  }
  .ele{
    transform: rotate(180deg);
    transform: scaleY(-1);
  }
  /* .icon:hover {
    background: rgb(208, 210, 211) radial-gradient(circle, transparent 1%, rgb(208, 210, 211) 1%) center/15000%;
    /* border-radius: 50%; */
  
  

    .navBtn:focus-within{
        border: none;
    }
    

/* Entire page */
.vidEditPage::-webkit-scrollbar {
  display: none;
}

.renderedCompLeft::-webkit-scrollbar{
  width: 10px;  

}
.renderedCompLeft::-webkit-scrollbar-thumb {
   
  background: rgba(0, 0, 0, 0.24);
  border-radius: 5px; 
 
}
    /* ProjectSettings */
    
   .aspectRatio{
    width:300px;
    height:40px;
    outline:none;
    border-radius: 8px;
    padding-left: 10px;
    border-width: 2px;
   }

   .aspectRatio:hover{
    outline: none;
    border-color: rgb(0, 152, 253);
    border-width: 2px;
   }
      

   .custom-dropdown {
    position: relative;
    display: inline-block;
    width: 300px;
  }
  
  .dropdown-toggle {
    background: none;
    /* outline: 2px solid grey; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    color: grey;
    cursor: pointer;
    text-align: left;
    width: 90%;
    border-radius: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    
  }
  
  .dropdown-toggle:hover {
    outline: 2px solid rgb(0, 152, 253);
    border: none;
  }
  
  .dropdown-menu {
    position: absolute;
    top:calc(100% + 10px);
    left: 0;
    width: 320px;
    height: 590px;
    background-color: white;
    border: 2px solid grey;
    border-top: none;
    display: none;
    border-radius: 6px;
    overflow-y: scroll;
   
  }
  
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }


  /* Color settings */

  .colorDiv{
    height:70px;
    width:90%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px -1px 0px 1px, rgba(0, 0, 0, 0.05) 1px 0px 0px 1px, rgba(0, 0, 0, 0.05) -1px 0px 0px 1px;
  
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px;
    color: grey;
  }

  /* img upload */

  .imgUpload{
    height:70px;
    width:90%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px, rgba(0, 0, 0, 0.05) 1px 0px 0px 1px, rgba(0, 0, 0, 0.05) -1px 0px 0px 1px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 15px;
    color: grey;
  }

  .uploadBtn{
    border: none;
    background-color: white;
    color: grey;
    font-weight: 600;
    font-size: large;
  }
.scrollableDiv{
  display:flex;
  flex-direction: column;
  align-items: center;
  height:55vh;
  width:90%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 5%;
  border-radius: 8px;
  box-shadow:rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 5%;
}
 
  .scrollableDiv::-webkit-scrollbar {
    width: 10px; 
  }
  .scrollableDiv::-webkit-scrollbar-thumb {
   
    background: rgba(0, 0, 0, 0.24);
    border-radius: 5px; 
   
}
 .frames{
  background: none;
    /* outline: 2px solid grey; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    color: grey;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    width: 90%;
    border-radius: 8px;
    height: 70px;
  }
  .frames:hover{
    outline: 2px solid rgb(0, 152, 253);
    border: none;
  }