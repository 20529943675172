
/* .customLandNav{
    background-color: black;
  
} */
.customLandNavLinks{
    font-size: 20px;
    font-weight: 600;
}
/* landingPage.css */

@keyframes shakeAnimation {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(5px);
    }
}
