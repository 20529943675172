.user-page {
  background-color: #f7f7f9;
  height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-rows: 7% 93%;
  grid-template-areas: 'header';

}

.user-nav {
  height: 4vh;
  grid-area: header;

}

.search {
  height: 38px;
  border: none;
  background-color: #f7f7f9;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: 275px;
  background-color: white;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.back-div{
  /* height: 50px; */
  display: flex;
  flex-direction: row;
  margin-left: 1%;
  /* margin-top: 40px; */
justify-content: center;
align-items: center;
}

.projectName{
  margin-top: 3px;
  font-weight: 600;

}

.search-bar {
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  border-radius: 15px;
  /* margin-top: 35px; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}

.search-bar:focus-within {
  border: 1px solid #cfe2ff;
  box-shadow: #cfe2ff 0px 0px 0px 3px;
  color: #0d6efd;
}

.search-icon {
  padding-left: 10px;
  padding-top: 5px;
  width: 25px;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.help-btn {
  height: 38px;
  width: 40px;
  border: 1px solid rgb(208 213 221);
  color: grey;
  border-radius: 4px;
  background-color: transparent;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.upgrade-btn {
  width: 120px;
  background-color: #fff1dd;
  height: 50px;
  margin-top: 40px;
  border: none;
  border-radius: 15px;
  margin-right: 6px;
}

.invite-btn {
  width: 120px;
  background-color: #eeeef0;
  height: 50px;
  margin-top: 40px;
  border: none;
  border-radius: 15px;

}

.faq {
  height: 50px;
  width: 50px;
  font-size: 30px;
  margin-top: 30px;
  border: none;
  background-color: #f7f7f9;

}

.bell {
  height: 50px;
  width: 50px;
  font-size: 25px;
  margin-top: 30px;
  border: none;
  background-color: #f7f7f9;

}

.badge-small {
  font-size: 0.8rem;
  padding: 0.2em 0.5em;
}

.userDp {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  outline: none;
  border: none;

}

.intro-text {
  font-weight: 450;
  margin-left: 50px;
  margin-top: 55px;
}

.create-btn,
.record-btn,
.golive-btn,
.podcast-btn {
  background-color: white;
  width: 10vw;
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  height: 60px;
  font-weight: 500;
  border: none;
  padding-left: 10px;
  transition: all 300ms ease-in;

}

.create-btn,
.record-btn,
.golive-btn,
.podcast-btn:focus-within {
  border: none;
  outline: none;
}

@media (min-width: 1080px) {

  .create-btn,
  .record-btn,
  .golive-btn,
  .podcast-btn {
    width: 13vw;
  }
}

@media (min-width: 1600px) {

  .create-btn,
  .record-btn,
  .golive-btn,
  .podcast-btn {
    width: 10vw;
  }
}

.create-btn:hover {
  color: #af68fe;
}

.create-icon {
  font-size: 20px;
  margin-right: 8px;
  color: #af68fe;
  background-color: #f4ebfe;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  font-weight: 500;
}

.record-btn:hover {
  color: #ff666c
}

.record-icon {
  font-size: 20px;
  margin-right: 8px;
  color: #ff666c;
  background-color: #fff0f0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  font-weight: 500;
}

.golive-icon {
  font-size: 20px;
  margin-right: 8px;
  color: #44b2fe;
  background-color: #ecf1ff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  font-weight: 500;
}

.golive-btn:hover {
  color: #44b2fe
}

.podcast-icon {
  font-size: 20px;
  margin-right: 8px;
  color: #ffa928;
  background-color: #fff6e8;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  font-weight: 500;
}

.podcast-btn:hover {
  color: #ffa928
}

.user-btns {
  margin-left: 50px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 1vw;

}

.allvid-btn {
  margin-left: auto;
  margin-right: 80px;
  border: none;
  background-color: #f7f7f9;
  color: gray;
  font-weight: 490;
}

.my-assets::-webkit-scrollbar {
  display: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.username {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  margin-top: 30px;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f0f0f0;
  border: none;
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
}

.user {
  margin-right: 40px;
}

.user .dropdown-menu {
  border: none;
  box-shadow: none;
  outline: none;
  height: fit-content;
  width: 80px;
  overflow-y: hidden;
  background-color: #80808021;

}

/* Optional: Remove the default blue outline on focus for dropdown items */
.user .dropdown-item:focus {
  outline: none;
}

.user>.nav-link {
  border: none;
  box-shadow: none;
  outline: none;
  height: max-content;
  width: fit-content;
}

/* Optional: Remove the default blue outline on focus for dropdown title */
.user>.nav-link:focus {
  outline: none;
  height: max-content;
  box-shadow: none;

}


/* Optional: Remove the default blue outline on focus for dropdown title */
.user>.nav-link:hover {
  outline: none;
  height: max-content;
}


.recentProjectsCard {
  position: relative;
  width: 26%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.recentProjectsThumbnail {
  width: 100%;
  border-radius: 8px;
  height: 250px;
  cursor: pointer;
}

@media (min-width: 1080px) {
  .card-text {
    font-size: 12px;
  }

  .recentProjectsThumbnail {
    height: 200px;
  }
}

@media (min-width: 1600px) {
  .card-text {
    font-size: 16px;
  }

  .recentProjectsThumbnail {
    height: 250px;
  }
}

@media (min-width: 2000px) {
  .card-text {
    font-size: 16px;
  }

  .recentProjectsThumbnail {
    height: 250px;
  }
}

.options-button {
  border: 1px solid black;
  /* opacity: 0;
  transition:opacity 0.3s; */
  font-size: 25px;
  outline: none;
  background-color: #f7f7f9;
  padding-top: 0%;
}


/* @media (min-width: 1080px){

}
@media (min-width: 1366px){
  
}
@media (min-width: 1440px){
  .recentProjectsThumbnail{
    height:25%;
  }
}
@media (min-width: 1920px){
  .recentProjectsThumbnail{
    height:25%;
  }
}
@media (min-width: 2880px){
  
} */